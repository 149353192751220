import vue from 'vue'
// 新增职位
function jobAdd (data) {
    return vue.$post('/saas/api/job/add', data)
}
//编辑职位
function jobEdit (data) {
    return vue.$post('/saas/api/job/edit', data)
}
//获取职位列表
function getJobList (data) {
    return vue.$get('/saas/api/job/getList', data)
}
//获取职位详情
function getJobInfo (data) {
    return vue.$get('/saas/api/job/getInfo', data)
}
//查看职位详情
function checkJobInfo (data) {
        return vue.$get('/saas/api/job/checkInfo', data)
}
//关注职位
function jobAttention (data) {
    return vue.$post('/saas/api/job/attention', data)
}
//取消关注职位
function cancelJobAttention (data) {
    return vue.$post('/saas/api/job/cancelAttention', data)
}
//获取收藏夹列表
function jobFavoritesList (data) {
    return vue.$get('/saas/api/JobFavorites/getList', data)
}
//获取收藏夹详情
function jobFavoritesInfo (data) {
    return vue.$get('/saas/api/JobFavorites/getInfo', data)
}
//新增收藏夹
function jobFavoritesAdd (data) {
    return vue.$post('/saas/api/JobFavorites/add', data)
}
//编辑收藏夹
function jobFavoritesEdit (data) {
    return vue.$post('/saas/api/JobFavorites/edit', data)
}
//删除收藏夹
function jobFavoritesDelete (data) {
    return vue.$post('/saas/api/JobFavorites/delete', data)
}
//加入收藏夹
function joinJobFavorites (data) {
    return vue.$post('/saas/api/job/joinFavorites', data)
}
//取消收藏夹
function cancelJobFavorites (data) {
    return vue.$post('/saas/api/job/cancelFavorites', data)
}
//放入回收站
function jobPutRecycle (data) {
    return vue.$post('/saas/api/job/putRecycle', data)
}
//还原职位
function jobRestore (data) {
    return vue.$post('/saas/api/job/restore', data)
}
//删除职位
function jobDelete (data) {
    return vue.$post('/saas/api/job/delete', data)
}
//获取日志列表
function getOperateLogList (data) {
    return vue.$get('/saas/api/job/getOperateLogList', data)
}
//保存标签
function saveTag (data) {
    return vue.$post('/saas/api/job/saveTag', data)
}
//移除职位候选人
function removeTalent (data) {
    return vue.$post('/saas/api/job/removeTalent', data)
}
//职位修改状态
function jobChangeStatus (data) {
    return vue.$post('/saas/api/job/changeStatus', data)
}
// 获取工作地址
function getWorkAddress (data) {
    return vue.$get('/saas/api/wholeNetwork/getWorkAddress', data)
}
//发布 到 网站
function publishToWebsite (data) {
    return vue.$post('/saas/api/wholeNetwork/publishToWebsite', data)
}
//获取职位发布记录
function getPublishRecordList (data) {
    return vue.$get('/saas/api/job/getPublishRecordList', data)
}
//保存凭证
function saveTicket (data) {
    return vue.$post('/saas/api/wholeNetwork/saveTicket', data)
}
// 扫码后触屏端投递职位
function bintangGetJobInfo(data){
    return vue.$get('/saas/api/job/bintangGetJobInfo', data)
}
function bintangAddTalent(data){
    return vue.$post('/saas/api/talent/bintangAddTalent', data)
}
export {
    bintangAddTalent,
    bintangGetJobInfo,
    jobAdd,
    jobEdit,
    getJobList,
    getJobInfo,
    checkJobInfo,
    jobAttention,
    cancelJobAttention,
    jobFavoritesList,
    jobFavoritesInfo,
    jobFavoritesAdd,
    jobFavoritesEdit,
    jobFavoritesDelete,
    joinJobFavorites,
    cancelJobFavorites,
    jobRestore,
    jobDelete,
    jobPutRecycle,
    getOperateLogList,
    saveTag,
    removeTalent,
    jobChangeStatus,
    getWorkAddress,
    publishToWebsite,
    getPublishRecordList,
    saveTicket
}
