import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index.vue'
Vue.use(VueRouter)

export const constantRoutes = [{
		path: '/',
		component: layout,
		redirect: '/talent',
		name: 'Dashboard',
		meta: {
			title: '工作台'
		}
	},
	{
		path: '/login',
		component: () => import('@/views/login/index'),
		name: 'login',
		meta: {
			title: '登录'
		}
	},
	{
		path: '/companyApply',
		component: () => import('@/views/login/companyApply'),
		name: 'companyApply',
		meta: {
			title: '企业试用'
		}
	},
	{
		path: '/inviteLink',
		component: () => import('@/views/login/inviteLink'),
		name: 'inviteLink',
		meta: {
			title: '邀请加入'
		}
	},
	{
		path: '/retrievePassword',
		component: () => import('@/views/login/retrievePassword'),
		name: 'retrievePassword',
		meta: {
			title: '找回密码'
		}
	},
	{
		path: '/404',
		component: () => import('@/views/404'),
		meta: {
			title: '404'
		}
	},
	{
		path: '/mobile/job',
		component: () => import('@/views/mobile/job'),
		meta: {
			title: '职位'
		}
	},
	{
		path: '/mobile/apply',
		component: () => import('@/views/mobile/apply'),
		meta: {
			title: '应聘'
		}
	}
]

const createRouter = () => new VueRouter({
	// mode: 'history', // require service support
	base: process.env.BASE_URL,
	routes: constantRoutes
})

const router = createRouter()
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
// 向 router 上添加一个，重新实例化 VueRouter 的方法
router.reloadRouter = function() {
	router.matcher = new VueRouter({
		routes: constantRoutes
	}).matcher
}
export default router
