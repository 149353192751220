/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-03-06 18:03:39
 * @LastEditors: pj
 * @LastEditTime: 2023-05-22 11:16:38
 */
import router from '../router/index.js';
import {
	removeToken
} from '@/utils/auth';
import {
	Message
} from 'element-ui'
import {
	mapMutations
} from 'vuex';
export function handlerHttpError(res) {
	switch (res.code) {
		case '3000':
			removeToken();
			// ...mapMutations({
			//     SET_USERINFO: 'user/SET_USERINFO',
			//     SET_WECHAT_BIND: 'user/SET_WECHAT_BIND',
			//     SET_USERNAME: 'user/SET_USERNAME',
			//     SET_TOKEN: 'user/SET_TOKEN',
			//     SET_FIRST_REQUEST: 'common/SET_FIRST_REQUEST',
			// }),
			router.push('/login');
			break;
		case '4000':
			Message.error(res.msg)
			break;
		case '5000':
			Message.error(res.msg)
			break;
		case '3201': //配置cookie
			break;
		case '3202': //引导申请试用
			break;
		default:
			// router.push('/404');
			if (res.msg) {
				Message.error(res.msg)
			}
			break
	}
}
